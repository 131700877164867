import "./ProductDescription.css";
import React, { useEffect } from "react";
import { BsBank2, BsClipboard2CheckFill, BsFillStarFill } from "react-icons/bs";
import { useUserData } from "../../../../contexts/UserDataProvider.js";
import { CgCheck, CgRepeat } from "react-icons/cg";
import { RiBus2Line, RiBusLine, RiCheckLine, RiRepeat2Line, RiRidingFill, RiRidingLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { fetchValidConfirmationEntries, numberWithCommas } from "../../../../utils/index.js";
import { Reviews } from "../Reviews/Reviews.jsx";
import { MoreInfo } from "../MoreInfo/MoreInfo.jsx";
import moment from "moment";
import CountdownTimer from "./Countdown.jsx";
import { useAuth } from "../../../../contexts/AuthProvider.js";

export const ProductDescription = ({ selectedProduct }) => {
  const navigate = useNavigate()
  const { auth } = useAuth();
    const {
    addToCartHandler,
    wishlistHandler,
    isProductInCart,
    isProductInWishlist,
    cartLoading,
    setActiveSize,
    activeSize,
    awaitingConfirmation,
    confirming,
    checkAvailability,

  } = useUserData();

  useEffect(() => {
    window.fbq('track', 'ViewContent', {
      content_ids: [selectedProduct?.slug], // 'REQUIRED': array of product IDs
      content_type: 'product', // RECOMMENDED: Either product or product_group based on the content_ids or contents being passed.
    });
    return () => {
      setActiveSize('')
    }
    
  }, [])
  const checkAwaiting = (productId)  => {
    var entry = fetchValidConfirmationEntries(productId)
    return entry.length ? new Date(entry[0].timestamp) : null
}
  const sendWhatsAppMessage = () => {
    const phoneNumber = '+2348039938596'; // Replace with the recipient's phone number, including country code
    const message = "Hello, I'm interested in learning more about the product - " + selectedProduct?.title + ` (https://360gadgetsafrica.com/product-details/${selectedProduct?.slug})`
    
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}` 
    // Check if WhatsApp is installed
    window.open(url,"__blank")
  }

 const canRetry = (currentDate) => {
    var twoHoursLater = new Date(currentDate);
    twoHoursLater.setHours(currentDate.getHours() + 2); // Add 2 hours to the current date
    // Check if the current time has passed the time two hours later
    return new Date() > twoHoursLater;
  }
  const getTimeAgo = (date) => {
    return moment().diff(moment(date), 'days') > 30
      ? "Over 30 days ago"
      : moment(date).fromNow();
  };
  return (
    <div className="product-details-description">
      <h1 className="product-name">{selectedProduct?.title}</h1>

      <div className="ratings-reviews"> 
        {/* <span>{selectedProduct?.rating["$numberDecimal"]}</span>{" "}
        <BsFillStarFill color={"orange"} /> */}
  
          <span className="review">({selectedProduct?.views}) views</span>
          <span className="review">- Price updated: {getTimeAgo(selectedProduct?.priceUpdatedAt)}</span>
     
      </div>

      <div className="product-price-container">
        <span className="product-original-price">
          ₦{numberWithCommas(selectedProduct?.original_price)}{" "}
        </span>
        <span className="product-discount-price">
          {" "}
          ₦{numberWithCommas(selectedProduct?.discounted_price)}
        </span>
      </div>

   

      {/* <span className="gender-container">
        <span>Genre</span>: {selectedProduct?.categoryId.title}
      </span> */}
      {selectedProduct.size?.length > 0 && <div className="description-container">
        <div className="xbold"> Size </div> <div>
          {selectedProduct.size.map(item => (
            <span onClick={() => (selectedProduct.is_stock && item.is_stock) && setActiveSize(item.title)} className={`select-phone ${item.title == activeSize ? 'active' : ''} ${!item.is_stock || !selectedProduct.is_stock ? 'x-disable' : ""}`}>{item.title}</span>
          ))}
        </div>
      </div>}

      <div className="tags">
        {selectedProduct?.is_stock ?
          <span className="in-stock">
            {selectedProduct.is_stock} in Stock
          </span> :
          <span className="out-of-stock">
            Out of stock
          </span>
        }
         {/* <span className="free-delivery">
           <RiCheckLine /> Free Delivery
          </span> */}
        {/* {selectedProduct?.trending && (
          <span className="trending">
            {selectedProduct?.trending ? "🔥 Trending" : ""}
          </span>
        )} */}

      </div>
      <div className="xperks">

      <div className="xperks-content">
          <p><BsBank2 /></p>
          <div>
            <p className="x-head">Payment options</p>
            <p> Enjoy multiple payment options through Flutterwave, and Paystack. Plus a convenient Pickup option.</p>
          </div>
        </div>
        <div className="xperks-content">
          <p><RiBusLine /></p>
          <div>
            <p className="x-head">24 - 48hours delivery</p>
            <p>Whether it's by road, airport, or straight to your doorstep, we've got you covered</p>
          </div>
        </div>
        <div className="xperks-content">
          <p><RiRepeat2Line /></p>
          <div>
            <p className="x-head">Return Policy</p>
            <p>  <Link to="/return-policy">Read our return policy</Link></p>
          </div>
        </div>
      </div>
      <div className="product-card-buttons-container">

      <button
          // disabled={cartLoading}
          // onClick={sendWhatsAppMessage}
          onClick={() => checkAvailability(selectedProduct)}
          loading={confirming }
          disabled={confirming || (checkAwaiting(selectedProduct?._id) &&  !canRetry(checkAwaiting(selectedProduct?._id)))}
          className="add-to-wishlist-btn whatsapp"
        >
        
          {checkAwaiting(selectedProduct?._id) && !canRetry(checkAwaiting(selectedProduct?._id))? 
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <span color="#fff" style={{fontSize: '12px', marginRight: '10px'}}>
                                Retry in
                            </span>
                         <CountdownTimer dateString={checkAwaiting(selectedProduct._id)} />
                        </div> : null}
         Confirm Availability
        </button>
      {!!selectedProduct.is_stock && <button
          disabled={cartLoading}
          onClick={() =>{
            addToCartHandler(selectedProduct, 'buynow')
          }}
          className="add-to-cart-btn"
        >
          Buy Now
        </button>}
        {!!selectedProduct.is_stock && <button
          disabled={cartLoading}
          onClick={() => addToCartHandler(selectedProduct)}
          className="add-to-wishlist-btn"
        >
          {!isProductInCart(selectedProduct) ? "Add to cart" : "Go to cart"}
        </button>}
        {auth?.vendorId && <button
          disabled={cartLoading}
          style={{marginTop: '.5em'}}
          onClick={() => navigate(`/load-items?token=${auth?.token}&productId=${selectedProduct?._id}&edit=true`)}
          className="add-to-wishlist-btn"
        >
          Edit product
        </button>}
        {/* <button
          disabled={cartLoading}
          onClick={() => wishlistHandler({ productId: selectedProduct?._id, title: selectedProduct?.title })}
          className="add-to-wishlist-btn"
        >
          {!isProductInWishlist(selectedProduct?._id)
            ? "Add to wishlist"
            : "Remove from wishlist"}
        </button> */}
      </div>
      {selectedProduct.description && <div className="description-container x-content">
      <h3>Description</h3>
      <p >
       {selectedProduct?.description}
      </p>
      </div>}
      {/* {window.innerWidth <= 425 && <Reviews productId={selectedProduct?._id} comments={selectedProduct?.comments}/>} */}
      
    </div>
  );
};
