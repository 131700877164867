import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchBanks, fetchDataPlan, getWallet, purchaseData, verifyBank, purchaseAirtime, withdraw, fetchTrans } from "../services/wallet";
import { useAuth } from "./AuthProvider";
import { calculateFee } from "../utils";
import { useData } from "./DataProvider";

const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [wallet, setWallet] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loadingWallet, setLoadingWallet] = useState(false);
  const [dataPlan, setDataPlan] = useState([]);
  const [vtuLoading, setVtuLoading] = useState(false);
  const [bankLoading, setBankLoading] = useState(false);
  const [transLoading, setTransLoading] = useState(false);
  const [isWithdrawVisible, setWithdrawIsVisible] = useState(false)
  const { state: { promo } } = useData();
  const navigate = useNavigate();

  const [verifyAccountLoading, setVerifyAccountLoading] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [vtuFormData, setVtuFormData] = useState({
    phone: '',
    plan: null,
  })
  const [withdrawalForm, setWithdrawalForm] = useState({
    amount: '',
    accountNumber: '',
    accountName: '',
    bankName: '',
    bankCode: '',
  });
  const [error, setError] = useState("");
  const { auth } = useAuth();
  useEffect(() => {
    const token = localStorage.getItem("token")
    if (token) {
      getBanks()
      getPlan()
     
    }
  }, [])

  useEffect(()=>{
    if(auth){
      loadWallet()
    }
  }, [auth])

  useEffect(() => {
    if (withdrawalForm.bankCode && withdrawalForm.accountNumber?.length > 8 && withdrawalForm.accountName == '') {
      verifyAccount(withdrawalForm)
    }
  }, [withdrawalForm])
  const loadWallet = async () => {
    if (auth?.verificationCode) return false
    setLoadingWallet(true)
    try {
      const wallet = await getWallet()
      setWallet(wallet)
    } catch (error) {
      console.log(error)
      // toast.error( error?.response?.data?.errors[0] || `An error has occured while loading wallet. please try again later`)
    }
    setLoadingWallet(true)
  }

  const getPlan = async () => {
    try {
      const plans = await fetchDataPlan()
      setDataPlan(plans.dataplan)
    } catch (error) {
      toast.error(error?.response?.data?.errors[0] || `An error has occured while loading data plans. please try again later`)
    }
  }
  const fetchTransactions = async () => {
    setTransLoading(true)
    try {
      const trans = await fetchTrans()
      setTransactions(trans.data)
    } catch (error) {
      toast.error(error?.response?.data?.errors[0] || `An error has occured while fetch transactions. please try again later`)
    }
    setTransLoading(false)
  }

  const buyPlan = async (data) => {
    if (!wallet) return (() => toast.error("Error, Please try again later"))()
    if (wallet && (data.plan.amount > wallet.balance)) return (() => toast.error("Insufficient funds. please add more funds"))()
    setVtuLoading(true)
    localStorage.setItem('vtuPhone', data?.phone)
    try {
      const transaction = await purchaseData(data);
      loadWallet()
      // setVtuFormData({ phone: '', plan: '', amount: '' })
      toast.success('Data purchase was successful')
      window.fbq('track', 'Purchase', { currency: "NGN", value: data.plan.amount });

      setTimeout(() => {
        window.location.reload()
      }, 2000);
    } catch (error) {
      toast.error(error?.response?.data?.errors[0] || `An error has occured. please try again later`)
    }
    setVtuLoading(false)
  }
  const buyAirtime = async (data) => {
    if (!wallet) return (() => toast.error("Error, Please try again later later"))()
    if (wallet && (data.amount > wallet.balance)) return (() => toast.error("Insufficient funds. please add more funds"))()
    if (data.amount < 50) return (() => toast.error("Minimum airtime purchase is ₦50.00"))()
    setVtuLoading(true)
    localStorage.setItem('vtuPhone', data?.phone)
    try {
      const transaction = await purchaseAirtime(data);
      loadWallet()
      setVtuFormData({ phone: '', plan: '', amount: '' })
      toast.success('Airtime purchase was successful')
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    } catch (error) {
      toast.error(error?.response?.data?.errors[0] || `An error has occured. please try again later`)
    }
    setVtuLoading(false)
  }

  const getBanks = async () => {
    setBankLoading(true)
    try {
      const data = await fetchBanks();
      setBankList(data?.data)
    } catch (error) {
      toast.error(error.response?.data?.errors[0] || `An error has occured. please try again later`)
    }
    setBankLoading(false)
  }


  const verifyAccount = async (data) => {
    setVerifyAccountLoading(true)
    try {
      const details = await verifyBank(data)
      setWithdrawalForm({ ...withdrawalForm, accountName: details.data.accountName })
    } catch (error) {
    }
    setVerifyAccountLoading(false)
  }

  const withdrawFunds = async () => {
    const fee = calculateFee(promo.withdrawalFee || 1.7, withdrawalForm.amount)
    if (!wallet) return toast.error("Error, Please try again later later")
    if (wallet.balance < (parseInt(withdrawalForm.amount) + fee)) return toast.error("Insufficient funds. please add more funds")
    if (withdrawalForm.amount < 500) return toast.error("Minimum withdrawal amount is ₦500")
    try {
      setTransLoading(true)
      const transaction = await withdraw({ ...withdrawalForm, 'reference': 'withdrawal', fee })
      setWithdrawIsVisible(false)
      setWithdrawalForm({})
      toast.success('Withdrawal was successful')
      setTransLoading(false) 
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    } catch (err) {
      setTransLoading(false)
      toast.error(err?.response?.data?.errors[0] || 'An error has occured. please try again later')
    }
  }
  return (
    <WalletContext.Provider
      value={{
        dataPlan,
        buyPlan,
        buyAirtime,
        getPlan,
        vtuLoading,
        vtuFormData,
        setVtuFormData,
        bankLoading,
        getBanks,
        bankList,
        withdrawalForm,
        setWithdrawalForm,
        verifyAccountLoading,
        wallet,
        withdrawFunds,
        loadWallet,
        transactions,
        fetchTransactions,
        transLoading,
        setWallet,
        setTransactions,
        isWithdrawVisible,
        setWithdrawIsVisible
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => useContext(WalletContext);
